import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Select,
  Switch,
  Table,
  Tabs,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { ImageListType } from "react-images-uploading";
import { colors } from "@/config/theme";
import { getCategories } from "@/features/all-orders";
import type { Product } from "@/features/all-orders";
import type { Category } from "@/types";
import dayjs from "dayjs";
import queryClient from "@/utils/query-client";
import useCategories from "@/hooks/use-categories";
import useStyledMessage from "@/hooks/use-styled-message";
import formatAmount from "@/helpers/format-amount";
import DishIcon from "@/components/dish-icon";
import AddIcon from "@/components/add-icon";
import EditIcon from "@/components/edit-icon";
import TrashIcon from "@/components/trash-icon";
import SuccessIcon from "@/components/success-icon";
import DishImage from "@/components/dish-image";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  createProduct,
  deleteProduct,
  getOneCategory,
  updateCategory,
  updateProduct,
  uploadFile,
} from "../api";
import type { FormValues, NewProduct, UpdateProduct } from "../types";
import ImgUpload from "../components/img-upload";

const { Header, Content } = Layout;

const name2Id = {
  breakfasts: 3,
  lunches: 4,
  bar: 2,
  snacks: 1,
  pp: 5,
  desserts: 6,
};

let status = true;

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function MenuControl(): React.ReactElement {
  const { t } = useTranslation();

  const { btns, currCategory, isModalCategoryOpen, setIsModalCategoryOpen } =
    useCategories({ editButton: true });
  const {
    btns: addDishBtns,
    currCategory: modalCategory,
    setCurrCategory,
  } = useCategories({ editButton: false });

  const form = Form.useForm()[0];

  const { contextHolder, open } = useStyledMessage();

  const [page, setPage] = useState(1);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [isModalOpenCategory, setIsModalOpenCategory] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalId, setModalId] = useState<number>();
  const [images, setImages] = useState([]);

  console.log(isModalCategoryOpen, "-sochaman");
  
  const createMutation = useMutation({
    mutationFn: async (payload: NewProduct) => {
      if (typeof modalId === "undefined") {
        await createProduct(payload);
      } else {
        await updateProduct(modalId, payload);
      }
    },
    onSuccess: () => {
      setIsModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["categories"]);
      setImages([]);
      setModalId(undefined);
    },
  });

  const createCategoryMutation = useMutation({
    mutationFn: async (payload: NewProduct) => {
      await updateCategory(isModalCategoryOpen, payload);
    },
    onSuccess: () => {
      // setIsModalCategoryOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["categories"]);
      setImages([]);
      setModalId(undefined);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteProduct,
    onSuccess: () => {
      setIsDelModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["categories"]);
      setImages([]);
      setModalId(undefined);
      open({
        type: "success",
        content: t("dish-deleted"),
        icon: <SuccessIcon style={{ color: colors.primary }} />,
      });
    },
  });

  const statusMutation = useMutation({
    mutationFn: async (data: { id: number; payload: UpdateProduct }) => {
      const { id, payload } = data;
      await updateProduct(id, payload);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["categories"]);
    },
  });

  const { data: categoriesData } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const res = await getCategories();
      return res;
    },
  });


  // const { data: OneCategory } = useQuery({
  //   queryKey: ["category-one"],
  //   queryFn: async () => {
  //     const res = await getOneCategory();
  //     return res;
  //   },
  // });

  const { data: OneCategory } = useQuery({
    queryKey: ["category-one", isModalCategoryOpen],
    queryFn: async () => {
      const res = await getOneCategory(+isModalCategoryOpen);
      return res;
    },
    enabled: !!isModalCategoryOpen,
    onSuccess: (data) => {
      // Update form fields with fetched data
      form.setFieldsValue({
        name_ru: data?.translations.ru?.name || "",
        name_uz: data?.translations.uz?.name || "",
        name_en: data?.translations.en?.name || "",
        status: data?.status,
      });
    },
  });

console.log(OneCategory?.translations, "one");

const initialValues = OneCategory
  ? {
      name_ru: OneCategory.translations.ru?.name || "",
      name_uz: OneCategory.translations.uz?.name || "",
      name_en: OneCategory.translations.en?.name || "",
      status: OneCategory.status,
    }
  : {};

  const categories = categoriesData?.results;

  const Breakfast = categories?.find(
    (category) => category.id === name2Id.breakfasts,
  );

  const Dinner = categories?.find(
    (category) => category.id === name2Id.lunches,
  );

  const Bar = categories?.find((category) => category.id === name2Id.bar);

  const SmallFood = categories?.find((category) => category.id === name2Id.snacks);

  const Snack = categories?.find((category) => category.id === name2Id.pp);

  const Dessert = categories?.find(
    (category) => category.id === name2Id.desserts,
  );

  const data = {
    Breakfast,
    Dinner,
    Bar,
    SmallFood,
    Snack,
    Dessert,
  };
  
  const products = data[currCategory]?.product;

  const showDelModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setModalId(id);
    }
    setIsDelModalOpen(true);
  };

  const handleDelete = (id: number): void => {
    deleteMutation.mutate(id);
  };

  const handleDelCancel = (): void => {
    setIsDelModalOpen(false);
  };

  const showModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setModalId(id);
    }
    setIsModalOpen(true);
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
    setModalId(undefined);
    form.resetFields();
    setImages([]);
  };

  const handleCategoryCancel = () => {
    form.resetFields();  // Reset fields when modal is closed
    setIsModalCategoryOpen(false);  // Close the modal
  };

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined,
  ): void => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList as never[]);
  };

  const submitHandler = (values: FormValues): void => {
    console.log(values);

    if (typeof images[0]?.file === "undefined") {
      const categoryId = name2Id[modalCategory];
      const productData = {
        ...values,
        name: undefined,
        translations: {
          ru: { name: values.name_ru, description: "a" },
          uz: { name: values.name_uz, description: "a" },
          en: { name: values.name_en, description: "a" },
        },
        category_id: categoryId,
        status: values.status ?? status,
        // image: ,
      };

      createMutation.mutate(productData);
    } else {
      const formData = new FormData();
      formData.append("file", images[0]?.file as File);

      void uploadFile(formData).then((res) => {
        const categoryId = name2Id[modalCategory];
        const productData = {
          ...values,
          name: undefined,
          translations: {
            ru: { name: values.name_ru, description: "a" },
            uz: { name: values.name_uz, description: "a" },
            en: { name: values.name_en, description: "a" },
          },
          category_id: categoryId,
          status: values.status ?? status,
          image: res.file,
        };

        createMutation.mutate(productData);
      });
    }
  };

  const submitCategory = async (values) => {
    try {
      // Prepare the productData for submission
      const productData = {
        ...values,
        name: undefined,
        translations: {
          ru: { name: values.name_ru, description: "a" },
          uz: { name: values.name_uz, description: "a" },
          en: { name: values.name_en, description: "a" },
        },
        category_id: OneCategory.id,
        status: values.status ?? OneCategory.status,
      };
  
      // Call your mutation or API to update the category
      await createCategoryMutation.mutateAsync(productData);
  
      // Close the modal if the update was successful
      handleCategoryCancel();
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Failed to update category:", error);
    }
  }
  const paginationProps = {
    defaultCurrent: page,
    total: 0,
  };

  const onPageChange: TableProps<Product>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
  };

  const columns = useMemo(() => {
    const allColumns: ColumnsType<Product> = [
      {
        key: "image",
        dataIndex: "image",
        render(value, record) {
          return (
            <div className="flex items-center gap-4">
              <DishImage src={value} />
              {record.translations.ru.name}
            </div>
          );
        },
      },
      // {
      //   key: "day",
      //   dataIndex: "day",
      //   render(value, record) {
      //     if (record.category_id === name2Id.lunches) {
      //       return t(value);
      //     }

      //     return null;
      //   },
      // },
      {
        key: "qty",
        dataIndex: "qty",
        render(value: number | string) {
          return `${formatAmount(+value)} ${t("pcs")}`;
        },
      },
      {
        key: "price",
        dataIndex: "price",
        render(value) {
          return `${formatAmount(value)} UZS`;
        },
      },
      {
        key: "status",
        dataIndex: "status",
        render(value: boolean, record) {
          return (
            <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
              <Switch
                checked={value}
                disabled={record.qty === 0}
                onChange={(val) => {
                  statusMutation.mutate({
                    id: record.id,
                    payload: { status: val },
                  });
                }}
              />
            </ConfigProvider>
          );
        },
      },
      {
        key: "edit/delete",
        dataIndex: "id",
        render(value: number, record) {
          return (
            <div className="flex gap-4">
              <Button
                icon={<EditIcon />}
                className="bg-[#eef0ff] text-[#5566ff] border-none"
                onClick={() => {
                  showModal(value);
                }}
              />
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#FF1F00",
                  },
                }}
              >
                <Button
                  icon={<TrashIcon />}
                  className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                  onClick={() => {
                    showDelModal(record.id);
                  }}
                />
              </ConfigProvider>
            </div>
          );
        },
        width: "0",
      },
    ];

    return allColumns;
  }, [t]);

  useEffect(() => {
    if (typeof modalId !== "undefined") {
      const initData = {
        translations: {
          ru: { name: undefined, description: undefined },
          uz: { name: undefined, description: undefined },
          en: { name: undefined, description: undefined },
        },
        image: undefined,
        price: undefined,
        qty: undefined,
        status: undefined,
      };

      const foundP: Product | undefined = categories?.reduce(
        (product, category) => {
          const found = category.product.find((dish) => dish.id === modalId);

          if (typeof found !== "undefined") {
            return found;
          }

          return product;
        },
        initData,
      );

      const foundCat = Object.keys(name2Id).find(
        (key) => name2Id[key as Category] === foundP?.category_id,
      );

      typeof foundP !== "undefined" &&
        typeof foundCat !== "undefined" &&
        setCurrCategory(foundCat as Category);

      if (typeof foundP !== "undefined") {
        setImages([
          {
            dataURL: foundP.image,
          },
        ]);
      }

      form.setFieldsValue({
        name_ru: foundP?.translations?.ru?.name,
        name_uz: foundP?.translations?.uz?.name,
        name_en: foundP?.translations?.en?.name,
        image: foundP?.image,
        price: foundP?.price,
        qty: foundP?.qty,
        status: foundP?.status,
        day: foundP?.day,
      });
    }
  }, [modalId, categories, form, setCurrCategory]);

  return (
    <>
      {contextHolder}
      <Header className="bg-white flex items-center justify-between border-y-[1px]">
        <h1 className="text-2xl font-semibold">{t("menu-control")}</h1>

        <div className="flex items-center">
          <Button
            type="primary"
            className="flex items-center"
            size="large"
            onClick={() => {
              showModal();
            }}
          >
            <AddIcon />
            {t("add-dish")}
          </Button>
        </div>
      </Header>

      <Content className="bg-[#f5f5f5] px-[50px] py-6">
        <div className="flex justify-between gap-8 mb-6">{btns}</div>

        {currCategory === "lunches" ? (
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  cardBg: "white",
                },
              },
            }}
            renderEmpty={() => (
              <div
                className="flex items-center justify-center"
                style={{
                  minHeight:
                    "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px - 40px)",
                }}
              >
                <div className="flex flex-col items-center gap-2">
                  <DishIcon style={{ color: colors.primary }} />
                  <span className="text-base leading-6 font-normal text-[#2F3138]">
                    {t("no-dish-added")}
                  </span>
                </div>
              </div>
            )}
          >
            <Tabs
              // onChange={onChange}
              // className="bg-red-400"
              // tabBarStyle={{}}
              className="custom-tab"
              hideAdd
              type="card"
              defaultActiveKey={weekdays[dayjs().day() - 1]}
              items={weekdays.map((id) => ({
                label:
                  id === weekdays[dayjs().day() - 1]
                    ? `${t(id)} (${t("today")})`
                    : t(id),
                key: id,
                children: (
                  <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={products?.filter(
                      (product) => product.day === id,
                    )}
                    onChange={onPageChange}
                    pagination={paginationProps}
                    showHeader={false}
                    loading={
                      createMutation.isLoading ||
                      deleteMutation.isLoading ||
                      statusMutation.isLoading
                    }
                    className="custom-lunches-table"
                  />
                ),
              }))}
            />
          </ConfigProvider>
        ) : (
          <ConfigProvider
            renderEmpty={() => (
              <div
                className="flex items-center justify-center"
                style={{
                  minHeight:
                    "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px)",
                }}
              >
                <div className="flex flex-col items-center gap-2">
                  <DishIcon style={{ color: colors.primary }} />
                  <span className="text-base leading-6 font-normal text-[#2F3138]">
                    {t("no-dish-added")}
                    
                  </span>
                </div>
              </div>
            )}
          >
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={data[currCategory]?.product}
              onChange={onPageChange}
              pagination={paginationProps}
              showHeader={false}
              loading={
                createMutation.isLoading ||
                deleteMutation.isLoading ||
                statusMutation.isLoading
              }
              className="custom-menu-table"
            />
          </ConfigProvider>
        )}
      </Content>

      <Modal
        title={null}
        closeIcon={null}
        open={isDelModalOpen}
        footer={null}
        centered
        onCancel={handleDelCancel}
      >
        <div className="absolute top-0 w-full left-0">
          <h1 className="font-semibold text-2xl leading-7 px-10 pt-4 flex items-center justify-between">
            {t("delete-dish")}

            <CloseOutlined
              style={{ fontSize: "16px" }}
              onClick={handleDelCancel}
            />
          </h1>
          <Divider className="my-4" />
        </div>

        <div className="flex flex-col items-center justify-center gap-4 mb-8 mt-[93px]">
          <TrashIcon style={{ color: "#FF1F00" }} />
          <span className="w-80 text-center">{t("confirm-delete-dish")}</span>

          <div className="flex gap-4">
            <Button
              className="bg-[#f5f5f5] border-none"
              type="text"
              onClick={handleDelCancel}
            >
              {t("cancel")}
            </Button>
            <ConfigProvider theme={{ token: { colorPrimary: "#FF1F00" } }}>
              <Button
                className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                onClick={() => {
                  typeof modalId !== "undefined" && handleDelete(modalId);
                }}
              >
                {t("delete")}
              </Button>
            </ConfigProvider>
          </div>
        </div>
      </Modal>

      <Modal
        title={typeof modalId !== "undefined" ? t("edit-dish") : t("add-dish")}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={850}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          onFinish={submitHandler}
          autoComplete="off"
          form={form}
          requiredMark={false}
        >
          <ImgUpload images={images} onChange={onChange} />

          <div className="flex justify-between gap-4 my-4">{addDishBtns}</div>

          <Form.Item
            label={t("dish-name-ru")}
            name="name_ru"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("dish-name-uz")}
            name="name_uz"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("dish-name-en")}
            name="name_en"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="flex gap-4">
            {modalCategory === "lunches" ? (
              <Form.Item
                label={t("week-day")}
                name="day"
                rules={[
                  {
                    required: true,
                    message: t("required-field") ?? "",
                  },
                ]}
                className="w-[181px]"
              >
                <Select
                  className="w-full"
                  options={weekdays.map((day) => ({
                    label: t(day),
                    value: day,
                  }))}
                />
              </Form.Item>
            ) : null}

            <Form.Item
              label={t("count-pc")}
              name="qty"
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                  whitespace: true,
                  message: t("field-min-0") ?? "",
                },
              ]}
            >
              <InputNumber className="w-full" />
            </Form.Item>

            <Form.Item
              label={t("price-per-pc")}
              name="price"
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 100,
                  whitespace: true,
                  message: t("field-min-100") ?? "",
                },
              ]}
            >
              <InputNumber className="w-full" />
            </Form.Item>
          </div>

          <div className="flex justify-between items-center">
            <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
              <Form.Item
                label={t("show-in-menu")}
                name="status"
                valuePropName="checked"
                required
              >
                <Switch
                  defaultChecked={status}
                  onChange={(val) => {
                    status = val;
                  }}
                />
              </Form.Item>
            </ConfigProvider>

            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center"
              size="large"
              loading={createMutation.isLoading}
            >
              {typeof modalId !== "undefined" ? t("edit-dish") : t("add-dish")}
            </Button>
          </div>
        </Form>
      </Modal>

      {/* category edit modal */}
      <Modal
        title={t("edit-category")}
        open={isModalCategoryOpen}
        footer={null}
        centered
        onCancel={handleCategoryCancel}
        width={850}
        afterClose={() => form.resetFields()}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          onFinish={submitCategory}
          autoComplete="off"
          form={form}
          requiredMark={false}
          initialValues={initialValues}
        >
          <Form.Item
            label={t("category-name-ru")}
            name="name_ru"
            rules={[
              {
                required: true,
                type: "string",
                min: 2,
                whitespace: true,
                message: t("field-min-2") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("category-name-uz")}
            name="name_uz"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("category-name-en")}
            name="name_en"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="flex justify-end items-center">
            {/* <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
              <Form.Item
                label={t("show-in-menu")}
                name="status"
                valuePropName="checked"
                required
              >
                <Switch
                  defaultChecked={status}
                  onChange={(val) => {
                    status = val;
                  }}
                />
              </Form.Item>
            </ConfigProvider> */}

            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center"
              size="large"
              loading={createCategoryMutation.isLoading}
            >
              {t("edit-category")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
