import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Input, Modal, Select, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import Mask from "react-input-mask";
import queryClient from "@/utils/query-client";
import useStyledMessage from "@/hooks/use-styled-message";
import formatAmount from "@/helpers/format-amount";
import InvoiceIcon from "@/components/invoice-icon";
import SuccessIcon from "@/components/success-icon";
import type { OrderStatus } from "@/types";
import type { Order, UpdateOrderData } from "../types";
import { archiveOrder, getPaymentTypes, updateOrder } from "../api";
import ArchiveIcon from "@/components/archive-icon";
import { colors } from "@/config/theme";

interface Props {
  order: Order | undefined;
  onSeeCheck: () => void;
  onSuccessNotify: (message: string) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OrderActions(props: Props): React.ReactElement | null {
  const {
    order,
    onSeeCheck,
    setIsOpen: setIsDrawerOpen,
    onSuccessNotify,
  } = props;

  const userPaid = order?.payment_status === "paid";

  const { t } = useTranslation();

  const { contextHolder, open } = useStyledMessage();

  const [orderTypeVal, setOrderTypeVal] = useState<string>();
  const [block, setBlock] = useState<string>();
  const [office, setOffice] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [navigation, setNavigation] = useState<string>();

  const [paymentType, setPaymentType] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [isArchive, setIsArchive] = useState(false);

  const statusMutation = useMutation({
    mutationFn: async (args: { id: number; data: { status: OrderStatus } }) => {
      await updateOrder(args.id, args.data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders", order?.status]);
      setIsDrawerOpen(false);
    },
  });

  const orderMutation = useMutation({
    mutationFn: async (args: { id: number; data: UpdateOrderData }) => {
      await updateOrder(args.id, args.data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders", order?.status]);
      setIsOpen(false);
      setIsDrawerOpen(false);
      setPaymentType(undefined);
    },
    onError: () => {
      open({
        type: "error",
        content: t("error"),
      });
    },
  });

  const archiveMutation = useMutation({
    mutationFn: async (args: { id: number }) => {
      await archiveOrder(args.id);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders", order?.status]);
      onSuccessNotify(`${order?.id} ${t("order-archived")}`);
      setIsOpen(false);
      setIsArchive(false);
      setIsDrawerOpen(false);
      setPaymentType(undefined);
    },
    onError: () => {
      open({
        type: "error",
        content: t("error"),
      });
    },
  });

  const { data: paymentTypesData } = useQuery({
    queryKey: ["payment-types"],
    queryFn: async () => {
      const res = await getPaymentTypes();
      return res;
    },
  });

  const paymentTypes = paymentTypesData?.results
    ?.filter((pType) => pType.status)
    ?.map((pType) => ({
      label: pType.translations.ru.name,
      value: pType.id,
      image: pType.image,
    }));

  const handleCancel = (): void => {
    setIsOpen(false);
    setPaymentType(undefined);
  };

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const allInfoProvided =
    orderTypeVal === "shipping" || order?.type === "shipping"
      ? !!(block ?? order?.block) &&
        !!(office ?? order?.cabinet) &&
        !!(phone ?? order?.phone) &&
        !!(navigation ?? order?.position)
      : true;

  const onPay = (): void => {
    orderMutation.mutate({
      id: order.id,
      data: {
        payment_status: "paid",
        payment_type: paymentType,
        block,
        phone,
        cabinet: office,
        position: navigation,
      },
    });
  };

  const onArchive = (): void => {
    archiveMutation.mutate({ id: order.id });
  };

  const onCloseOrder = (id: number): void => {
    statusMutation.mutate({ id, data: { status: "close" } });
  };

  useEffect(() => {
    setOrderTypeVal(undefined);
  }, [order]);

  if (typeof order === "undefined") return null;

  return (
    <>
      {contextHolder}

      {userPaid ? (
        <div className="bg-[#f5f5f5] rounded-t-2xl absolute bottom-0 w-full left-0 p-6">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <span>{t("client-id")}</span>
              <span>{order?.user?.id}</span>
            </div>
            <div className="flex justify-between">
              <span>{t("source")}</span>
              <span>{t(order?.source)}</span>
            </div>
            <div className="flex justify-between">
              <span>{t("total-position-count")}</span>
              <span>
                {order?.items?.length ?? 0} {t("positions")}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("order-type")}</span>
              <span>{t(order?.type)}</span>
            </div>
            {orderTypeVal === "shipping" || order.type === "shipping" ? (
              <>
                <div className="flex justify-between">
                  <span>{t("block-office")}</span>
                  <span className="flex gap-2">
                    {typeof order.block !== "undefined" ? (
                      `${t("block-number")}${order.block}`
                    ) : (
                      <Input
                        placeholder={t("block-number") ?? ""}
                        className="w-20"
                        onChange={(e) => {
                          setBlock(e.target.value);
                        }}
                        value={block}
                      />
                    )}
                    {", "}
                    {typeof order.cabinet !== "undefined" ? (
                      `${t("office-number")}${order.cabinet}`
                    ) : (
                      <Input
                        placeholder={t("office-number") ?? ""}
                        className="w-32"
                        onChange={(e) => {
                          setOffice(e.target.value);
                        }}
                        value={office}
                      />
                    )}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span>{t("office-location")}</span>
                  <span>
                    {typeof order?.position !== "undefined" ? (
                      order.position
                    ) : (
                      <Input
                        onChange={(e) => {
                          setNavigation(e.target.value);
                        }}
                        value={navigation}
                      />
                    )}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span>{t("phone")}</span>
                  <span>
                    {typeof order?.phone !== "undefined" ? (
                      order.phone
                    ) : (
                      <Mask
                        maskChar={null}
                        placeholder="+998 XX XXX XXXX"
                        mask="+\9\98 99 999 99 99"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        value={phone}
                      >
                        {(maskProps: Props) => <Input {...maskProps} />}
                      </Mask>
                    )}
                  </span>
                </div>
              </>
            ) : null}

            {typeof order?.payment_type !== "undefined" &&
              order?.payment_type !== null && (
                <div className="flex justify-between">
                  <span>{t("payment-method")}</span>
                  <span>{order?.payment_type?.translations?.ru?.name}</span>
                </div>
              )}
            <div className="flex justify-between">
              <span>{t("status")}</span>
              <span>
                <Tag bordered={false} color="success" className="mr-0">
                  {t(order?.payment_status)}
                </Tag>
              </span>
            </div>
          </div>

          <Divider />

          <div className="flex items-center justify-between">
            <span className="text-base leading-5 font-semibold">
              {t("total-price")}
            </span>
            <span className="text-2xl leading-7 font-bold">
              {formatAmount(order.total)} UZS
            </span>
          </div>

          <div className="flex gap-4 mt-6">
            <Button
              size="large"
              className="flex-1 flex items-center justify-center text-[#5566ff] border-[#5566ff]"
              onClick={onSeeCheck}
            >
              <InvoiceIcon />
              {t("see-check")}
            </Button>
            <Button
              type="primary"
              size="large"
              className="flex-1 flex items-center justify-center"
              onClick={() => {
                onCloseOrder(order.id);
              }}
              loading={statusMutation.isLoading}
            >
              <SuccessIcon />
              {t("close-order")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="bg-[#f5f5f5] rounded-t-2xl absolute bottom-0 w-full left-0 p-6">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <span>{t("client-id")}</span>
              <span>{order?.user?.id}</span>
            </div>
            <div className="flex justify-between">
              <span>{t("source")}</span>
              <span>{t(order?.source)}</span>
            </div>
            <div className="flex justify-between">
              <span>{t("total-position-count")}</span>
              <span>
                {order?.items?.length} {t("positions")}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("order-type")}</span>
              <span>
                {typeof order?.type === "string" ? (
                  t(order.type)
                ) : (
                  <Select
                    className="w-36"
                    placeholder={t("choose")}
                    options={[
                      { label: t("self"), value: "self" },
                      { label: t("shipping"), value: "shipping" },
                    ]}
                    value={orderTypeVal}
                    onChange={setOrderTypeVal}
                  />
                )}
              </span>
            </div>

            {orderTypeVal === "shipping" || order.type === "shipping" ? (
              <>
                <div className="flex justify-between">
                  <span>{t("block-office")}</span>
                  <span className="flex gap-2">
                    {typeof order.block !== "undefined" ? (
                      `${t("block-number")}${order.block}` // ${order.block ?? ''}
                    ) : (
                      <Input
                        placeholder={t("block-number") ?? ""}
                        className="w-20"
                        onChange={(e) => {
                          setBlock(e.target.value);
                        }}
                        value={block}
                      />
                    )}
                    {", "}
                    {typeof order.cabinet !== "undefined" ? (
                      `${t("office-number")}${order.cabinet}`
                    ) : (
                      <Input
                        placeholder={t("office-number") ?? ""}
                        className="w-32"
                        onChange={(e) => {
                          setOffice(e.target.value);
                        }}
                        value={office}
                      />
                    )}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span>{t("office-location")}</span>
                  <span>
                    {typeof order?.position !== "undefined" ? (
                      order.position
                    ) : (
                      <Input
                        onChange={(e) => {
                          setNavigation(e.target.value);
                        }}
                        value={navigation}
                      />
                    )}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span>{t("phone")}</span>
                  <span>
                    {typeof order?.phone !== "undefined" ? (
                      order.phone
                    ) : (
                      <Mask
                        maskChar={null}
                        placeholder="+998 XX XXX XXXX"
                        mask="+\9\98 99 999 99 99"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        value={phone}
                      >
                        {(maskProps: Props) => <Input {...maskProps} />}
                      </Mask>
                    )}
                  </span>
                </div>
              </>
            ) : null}

            {typeof order?.payment_type !== "undefined" &&
              order?.payment_type !== null && (
                <div className="flex justify-between">
                  <span>{t("payment-method")}</span>
                  <span>{order?.payment_type?.translations?.ru?.name}</span>
                </div>
              )}
            <div className="flex justify-between">
              <span>{t("status")}</span>
              <span>
                <Tag bordered={false} color="error" className="mr-0">
                  {t(order?.payment_status)}
                </Tag>
              </span>
            </div>
          </div>

          <Divider />

          <div className="flex items-center justify-between">
            <span className="text-base leading-5 font-semibold">
              {t("total-price")}
            </span>
            <span className="text-2xl leading-7 font-bold">
              {formatAmount(order.total)} UZS
            </span>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-6">
            <Button
              type="primary"
              size="large"
              className="flex items-center bg-[#5566FF] bg-opacity-20 justify-center font-medium text-[#5566FF]"
              onClick={() => {
                handleOpen(), setIsArchive(true);
              }}
              disabled={!allInfoProvided}
            >
              <ArchiveIcon />
              {t("archive")}
            </Button>

            <Button
              type="primary"
              size="large"
              className="flex items-center justify-center font-medium"
              onClick={() => {
                handleOpen(), setIsArchive(false);
              }}
              disabled={!allInfoProvided}
            >
              <SuccessIcon />
              {t("pay")}
            </Button>
          </div>
        </div>
      )}

      <Modal
        open={isOpen}
        title={null}
        footer={null}
        closeIcon={null}
        centered
        onCancel={handleCancel}
      >
        <div className="absolute top-0 w-full left-0">
          <h1 className="font-semibold text-2xl leading-7 px-10 pt-4 flex items-center justify-between">
            {isArchive ? t("archive") : t("choose-payment-method")}

            <CloseOutlined
              style={{ fontSize: "16px" }}
              onClick={handleCancel}
            />
          </h1>
          <Divider className="my-4" />
        </div>

        <div className="flex flex-col justify-center gap-6 mb-2 mt-16">
          {isArchive ? (
            <>
              <div className="flex flex-col justify-center items-center my-6 gap-6">
                <ArchiveIcon style={{ color: "#5566ff" }} />
                <span className="w-80 text-center">
                  {t("confirm-archive-order")}
                </span>
              </div>
              <Button
                type="primary"
                className="self-end h-11 rounded-[5px] font-medium"
                // disabled={!paymentType}
                // loading={onArchive.isLoading}
                onClick={onArchive}
              >
                {t("archive")}
              </Button>
            </>
          ) : (
            <>
              <div className="flex flex-wrap gap-2">
                {paymentTypes?.map((pType) => (
                  <span
                    key={pType.value}
                    className={clsx(
                      "font-medium bg-[#ECEDEE] hover:shadow hover:bg-[#EEF0FF] border hover:border-[#5566ff] cursor-pointer rounded-lg w-[49%] py-6 px-8 flex items-center justify-center gap-2",
                      paymentType === pType.value
                        ? "shadow bg-[#EEF0FF] border-[#5566ff]"
                        : "",
                    )}
                    aria-hidden
                    onClick={() => {
                      setPaymentType(pType.value);
                    }}
                  >
                    <img src={pType.image} alt="payment type" width={24} />
                    {pType.label}
                  </span>
                ))}
              </div>

              <Button
                type="primary"
                className="self-end h-11 rounded-[5px] font-medium"
                disabled={!paymentType}
                loading={orderMutation.isLoading}
                onClick={onPay}
              >
                {t("pay")}
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
