import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Divider, QRCode } from "antd";
import dayjs from "dayjs";
import formatAmount from "@/helpers/format-amount";
import brandLogo from "/brand-logo.svg";
import { getChequeData } from "../features/all-orders";
import type { Order } from "../features/all-orders";

export default function Check(): React.ReactElement {
  const { t } = useTranslation();

  const [order, setOrder] = useState<Order>();
  const [check, setCheck] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [params, setParams] = useState({
    t: "",
    check_number: "14425573",
    time: "",
    fiskal: "128202357893",
  });

  const { data } = useQuery({
    queryKey: ["cheque", order?.id],
    queryFn: async () => {
      if (typeof order?.id === "number") {
        const res = await getChequeData(order?.id);
        return res;
      }
      return undefined;
    },
    enabled: typeof order?.id === "number" && isOpen,
  });

  // Extract parameters dynamically from check_url
  useEffect(() => {
    if (data?.check_url) {
      const searchParams = new URL(data?.check_url).searchParams;
      const paramsObj = {
        t: searchParams.get("t") || "",
        check_number: searchParams.get("r") || "",
        time: searchParams.get("c") || "",
        fiskal: searchParams.get("s") || "",
      };
      setParams(paramsObj);
    }
  }, [check]);

  useEffect(() => {
    window.onmessage = (e) => {
      if (e.data.action === "update") {
        setOrder(e.data.order);
        setIsOpen(e.data.isOpen);
      }
      if (e.data.action === "download") {
        window.print();
      }
    };
  }, []);

  const getOfdData = async () => {
    try {
      const response = await fetch(data?.check_url, {
        method: "GET",
        redirect: "follow",
        mode: "no-cors",
      });

      console.log("Response Status:", response); // Log the status code
      console.log("Response Headers:", response.headers); // Log all headers

      const redirectedUrl = response.headers.get("location");
      console.log("Redirected URL:", redirectedUrl); // Log the redirected URL
      if (response.status === 302) {
        if (redirectedUrl) {
          // Fetch the new URL
          const finalResponse = await fetch(redirectedUrl);
          console.log("Final Fetch Response:", finalResponse); // Log the final fetch response
          if (finalResponse.ok) {
            const finalData = await finalResponse.json();
            console.log("Final Data:", finalData); // Log the final data
            setCheck(redirectedUrl); // Update state with the redirected URL if needed
            return finalData;
          } else {
            console.error("Error with final fetch:", finalResponse.status);
          }
        } else {
          console.error("Location header not found");
        }
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Network or server error:", error);
    }
  };

  useEffect(() => {
    if (data?.check_url) {
      getOfdData();
    }
  }, [data?.check_url]);

  return (
    <div className="mono text-[#6D7278] w-[301px] m-auto text-xs">
      <div className="flex items-center justify-between">
        <img src={brandLogo} alt="logo" width={48} />
        {dayjs().format("DD.MM.YYYY, HH:mm")}
      </div>
      <h1 className="text-center leading-5 tracking-tighter text-black my-4">
        {t("order")} #{order?.id}
      </h1>
      <p className="my-2">******************************************</p>
      <p className="tracking-wide">UCAFE --- IT Park Узбекстан</p>
      <p className="my-2">******************************************</p>
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <span className="tracking-wide">{t("datetime")}</span>
          <span className="tracking-tighter text-black">
            {dayjs(order?.created_at).format("DD.MM.YYYY • HH:mm:ss")}
          </span>
        </div>

        <div className="flex justify-between tracking-wide">
          <span>{t("order-type")}</span>
          <span className="text-black">{t(order?.type ?? "")}</span>
        </div>

        <div className="flex justify-between tracking-wide">
          <span>{t("payment-method")}</span>
          <span className="text-black">
            {order?.payment_type?.translations?.ru?.name}
          </span>
        </div>

        <div className="flex justify-between tracking-wide">
          <span>{t("cashier")}</span>
          <span className="text-black">{localStorage.getItem("username")}</span>
        </div>
      </div>
      <p className="my-2">-----------------------------------------</p>
      <div className="abcd">
        <div className="flex items-center gap-2 mb-2">
          <span className="inline-block w-7/12 tracking-wider">
            {t("product-name")}
          </span>

          <span className="inline-block w-2/12 tabular-nums tracking-wider">
            {t("qty")}
          </span>
          <span className="inline-block w-3/12 tabular-nums text-black tracking-tighter">
            {t("price")}
          </span>
        </div>
        <p className="my-2">-----------------------------------------</p>
        {data?.items?.map((item) => (
          <div key={item.id} className="flex items-center gap-2 mb-2">
            <span className="inline-block w-7/12 whitespace-nowrap text-ellipsis overflow-hidden tracking-wider">
              {item?.product?.translations?.ru?.name}
            </span>

            <span className="inline-block w-2/12 tabular-nums tracking-wider">
              {item?.qty}
            </span>
            <span className="inline-block w-3/12 tabular-nums text-black tracking-tighter">
              {formatAmount(item.qty * item.price)}
            </span>
          </div>
        ))}
      </div>
      <p className="my-2">-----------------------------------------</p>
      <div className="flex items-center gap-2 mb-2">
        <span className="inline-block w-7/12 tracking-wider">
          {t("total-to-pay")}
        </span>

        <span className="inline-block w-2/12 tabular-nums">{}</span>
        <span className="inline-block w-3/12 tabular-nums text-black tracking-tighter">
          {formatAmount(order?.total ?? 0)}
        </span>
      </div>
      <p className="my-2">-----------------------------------------</p>
      <div className="tracking-wider">{t("order-comments")}</div>
      <Divider className="my-8 bg-gray-300" />
      <Divider className="my-8 bg-gray-300" />
      <Divider className="my-8 bg-gray-300" />
      {data?.payment_type?.id === 5 && (
        <>
          <div className="flex justify-between tracking-wide mb-2">
            <span>{t("check-number")}</span>
            <span className="text-black">{params?.check_number}</span>
          </div>
          <div className="flex justify-between tracking-wide">
            <span>{t("fiskal-symbol")}</span>
            <span className="text-black">{params?.fiskal}</span>
          </div>
          <div className="flex justify-center mt-4">
            {/* <QRCode type="svg" value={check} />
             */}
            <QRCode type="svg" value={data?.check_url} />
          </div>
        </>
      )}

      <p className="my-2">******************************************</p>
      <div className="text-center tracking-wider flex flex-col gap-3">
        <p>САЙТ: https://ucafe.uz</p>
        <p>Тел: (95) 405 33 33</p>
        <p>СПАСИБО! ЖДЕМ ВАС СНОВА!</p>
      </div>
      <p className="my-2">******************************************</p>
    </div>
  );
}
