import formatAmount from "@/helpers/format-amount";
import { Card } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useMediaQuery } from "react-responsive";

ChartJS.register(ArcElement);

const PaymentChart = ({ data: mainData, title }: any) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const data = {
    labels: mainData?.map((val) => val.type ?? val.category),
    datasets: [
      {
        label: "",
        data: mainData?.map((val) => val.price ?? val.total),
        backgroundColor: [
          "#2BC128",
          "#F5BE43",
          "#FF9159",
          "#5566FF",
          "#4BCFF9",
          "#9747FF",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // borderRadius: 10,
    // boxWidth,
    boxHeight: 100,

    layout: {
      padding: isTabletOrMobile ? { top: 10 } : { top: 30, bottom: 30 },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;

          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            let percentage = Math.round((value / sum) * 100) + "%";
            return percentage;
          }
        },
        color: "#fff",
        font: {
          size: 16,
        },
      },
      tooltip: {
        usePointStyle: true,
        boxWidth: 15,
        boxHeight: 10,
        // displayColors: false,
        callbacks: {
          label: function (context) {
            let value = context.raw;
            return `${formatAmount(value)} UZS`;
          },
        },
      },
      legend: {
        fullSize: true,
        position: isTabletOrMobile ? "bottom" : ("right" as const),
        align: isTabletOrMobile ? "start" : "center",
        labels: {
          // padding: isTabletOrMobile ? 20 : 40,
          usePointStyle: true,
          boxWidth: 15,
          boxHeight: 10,
          color: "#2F3138",
          font: {
            size: 16,
          },
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            const total = datasets[0].data.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0,
            );

            return datasets[0].data.map((data, i) => {
              const percentage = (datasets[0].data[i] * 100) / total;

              return {
                text: `${chart.data.labels[i]}:  ${formatAmount(
                  data,
                )} UZS  –  ${percentage.toFixed(
                  percentage < 1 ? 1 : undefined,
                )}%`,
                fillStyle: datasets[0].backgroundColor[i],
                lineWidth: 0,
                hidden: !chart.getDataVisibility(i),
                index: i,
              };
            });
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <>
      {isTabletOrMobile ? (
        <h1 className="text-lg font-semibold w-full block">{title}</h1>
      ) : null}
      <Card
        size={isTabletOrMobile ? "small" : "default"}
        style={{ boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.08)" }}
      >
        {isTabletOrMobile ? null : (
          <h1 className="text-lg font-semibold">{title}</h1>
        )}
        <div className="min-h-[345px] md:max-h-[325px]">
          <Doughnut
            data={data}
            options={options}
            style={{ width: "100%", height: "100%" }}
            plugins={[ChartDataLabels, Legend, Tooltip]}
          />
        </div>
      </Card>
    </>
  );
};

export default PaymentChart;
