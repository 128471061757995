/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Layout,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import type { PaginationProps } from "antd";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getOrders } from "@/features/all-orders";
import { colors } from "@/config/theme";
import { uid } from "radash";
import dayjs from "dayjs";
import formatAmount from "@/helpers/format-amount";
import NoteIcon from "@/components/note-icon";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { Order } from "@/features/all-orders";
import type { OrderType } from "@/types";
import DownloadIcon from "@/components/download-icon";
import clsx from "clsx";
import {
  downloadExcel,
  getOrderExpenseStats,
  getOrderRaiseStats,
  getOrderTypeStats,
  getOrderedProductsStats,
  getPaymentTypeStats,
  getProductCategoryStats,
} from "../api";
import OrderTypeIcon from "@/components/order-type-icon";
import "dayjs/locale/ru";
import TotalStatsCard from "../components/total-stats-card";
import SalesChart from "../components/sales-chart";
import PaymentChart from "../components/payment-chart";
import PreferencesIcon from "@/components/preferences-icon";
import LogoutIcon from "@/components/logout-icon";
import { useAuthContext } from "@/contexts/auth-context";
import MobileOrderCard, {
  MobileOrderCardSkeleton,
} from "../components/mobile-order-card";
import EggsIcon from "@/components/eggs-icon";
import DishIcon from "@/components/dish-icon";
import CoffeeIcon from "@/components/coffee-icon";
import ChocolateIcon from "@/components/chocolate-icon";
import BrocoliIcon from "@/components/brocoli-icon";
import CakeIcon from "@/components/cake-icon";
import MoneyIcon from "@/components/money-icon";
import { useNavigate } from "react-router-dom";

const { Header, Content } = Layout;

interface Filters {
  self: boolean;
  shipping: boolean;
  min_date: string | null;
  max_date: string | null;
  payment_type: number | undefined;
  date_type: string | undefined;
}

export default function Report(): React.ReactElement {
  const { t } = useTranslation();
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [productsPageSize, setProductsPageSize] = useState(10);
  const [productsPage, setProductsPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("orders-list");

  const [filters, setFilters] = useState<Filters>({
    self: true,
    shipping: true,
    min_date: null,
    max_date: null,
    date_type: "day",
    payment_type: undefined,
  });

  const [filtersDraft, setFiltersDraft] = useState<Filters>({
    self: true,
    shipping: true,
    min_date: null,
    max_date: null,
    date_type: "day",
    payment_type: undefined,
  });

  const dateTypes = [
    { label: t("День"), value: "day" },
    { label: t("Неделя"), value: "week" },
    { label: t("Месяц"), value: "month" },
    { label: t("3 месяца"), value: "three_month" },
    { label: t("6 месяцев"), value: "six_month" },
    { label: t("Год"), value: "year" },
  ];

  const defaultFilters = {
    ordering: "-id",
    payment_status: "paid",
    date_type: filters.date_type,
    min_date:
      typeof filters.min_date === "string" &&
      typeof filters.max_date === "string" &&
      filters.min_date !== filters.max_date
        ? filters.min_date
        : undefined,

    max_date:
      typeof filters.min_date === "string" &&
      typeof filters.max_date === "string" &&
      filters.min_date !== filters.max_date
        ? filters.max_date
        : undefined,

    date:
      typeof filters.min_date === "string" &&
      typeof filters.max_date === "string" &&
      filters.min_date === filters.max_date
        ? filters.min_date
        : undefined,
    payment_type: filtersDraft.payment_type,
  };

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["orders", filtersDraft],
    queryFn: async () => {
      const raiseStats = await getOrderRaiseStats(defaultFilters);
      const typeRes = await getOrderTypeStats(defaultFilters);
      const paymentTypeRes = await getPaymentTypeStats(defaultFilters);
      const expenseStats = await getOrderExpenseStats(defaultFilters);
      const categoryStats = await getProductCategoryStats(defaultFilters);

      return {
        raiseStats,
        typeStats: typeRes.data,
        paymentStats: paymentTypeRes.data,
        expenseStats,
        categoryStats,
      };
    },
  });

  const {
    data: productsData,
    isLoading: isProductsLoading,
    isFetching: isProductsFetching,
  } = useQuery({
    queryKey: ["sold-items", filtersDraft, productsPage, productsPageSize],
    queryFn: async () => {
      const type = getOrderType(filtersDraft);

      const finishedFilters = {
        type,
        page: productsPage,
        page_size: productsPageSize,

        ...defaultFilters,
      };

      const res = await getOrderedProductsStats(finishedFilters);

      return res;
    },
  });

  const {
    data: ordersData,
    isLoading: isOrdersLoading,
    isFetching: isOrdersFetching,
  } = useQuery({
    queryKey: ["orders", filtersDraft, page, pageSize],
    queryFn: async () => {
      const type = getOrderType(filtersDraft);

      const finishedFilters = {
        type,
        page,
        page_size: pageSize,

        ...defaultFilters,
      };

      const res = await getOrders(finishedFilters);

      return res;
    },
  });

  const orders = ordersData?.results;
  // const orders = data?.results.sort((a, b) => b.id - a.id);

  // const totalCost =
  //   orders?.reduce((total, currOrder) => total + (currOrder.total ?? 0), 0) ??
  //   0;

  // const totalCost = (() => {
  //   if (Array.isArray(orders)) {
  //     return orders.reduce((total, currOrder) => {
  //       if (typeof currOrder.total === "number") {
  //         return total + currOrder.total;
  //       }
  //       return total;
  //     }, 0);
  //   }
  //   return 0;
  // })();

  const ordersPaginationProps: PaginationProps = {
    defaultCurrent: page,
    defaultPageSize: pageSize,
    total: ordersData?.count ?? 0,
    locale: { items_per_page: "" },
  };

  const productsPaginationProps: PaginationProps = {
    defaultCurrent: productsPage,
    defaultPageSize: productsPageSize,
    total: productsData?.count ?? 0,
    locale: { items_per_page: "" },
  };

  const onPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
    setPageSize(pagination.pageSize ?? 10);
  };

  const onProductsPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setProductsPage(pagination.current ?? 1);
    setProductsPageSize(pagination.pageSize ?? 10);
  };

  const hide = (): void => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  const download = (): void => {
    void downloadExcel().then((res) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      link.download = `file_${uid(8, "-")}.xlsx`;
      link.click();
    });
  };

  const onApplyChanges = (): void => {
    setFiltersDraft(filters);
  };

  const columns = useMemo(() => {
    const ordersColumns: ColumnsType<Order> = [
      {
        key: "order-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("Наименование")}
          </span>
        ),
        dataIndex: "id",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-semibold text-[#2F3138]">
              Заказ № {value}
            </span>
          );
        },
      },
      {
        key: "created_at",
        title: (
          <span className="font-medium text-[#7D848B]">{t("order-date")}</span>
        ),
        dataIndex: "created_at",
        render(value) {
          return (
            <span className="text-sm whitespace-nowrap text-[#2F3138]">
              {dayjs(value).locale("ru").format("DD MMMM, YYYY | HH:MM")}
            </span>
          );
        },
      },
      {
        key: "type",
        title: (
          <span className="font-medium text-[#7D848B]">{t("order-type")}</span>
        ),
        dataIndex: "type",
        render(value) {
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-fit text-xs text-[#7D848B]">
              <OrderTypeIcon type={value} />
              {t(value)}
            </Tag>
          );
        },
      },
      {
        key: "payment_type",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("payment-method")}
          </span>
        ),
        dataIndex: "payment_type",
        render(value: { translations: { ru: { name: string } } }) {
          return (
            <span className="text-sm text-[#2F3138]">
              {value.translations.ru.name}
            </span>
          );
        },
      },
      {
        key: "total",
        title: (
          <span className="font-medium text-[#7D848B]">{t("order-cost")}</span>
        ),
        dataIndex: "total",
        render(value: number) {
          return (
            <span className="text-sm text-[#2F3138] whitespace-nowrap tabular-nums">
              {formatAmount(value ?? 0)} UZS
            </span>
          );
        },
      },
    ];

    const productsColumns: ColumnsType<Order> = [
      {
        key: "name-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("product-name")}
          </span>
        ),
        dataIndex: "name",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-semibold text-[#2F3138]">
              {value}
            </span>
          );
        },
      },
      {
        key: "category",
        title: (
          <span className="font-medium text-[#7D848B]">{t("category")}</span>
        ),
        dataIndex: "category",
        render(value) {
          function getIcon(id: number): React.ReactElement | null {
            if (id === 3) return <EggsIcon />;
            if (id === 4) return <DishIcon />;
            if (id === 2) return <CoffeeIcon />;
            if (id === 1) return <ChocolateIcon />;
            if (id === 5) return <BrocoliIcon />;
            if (id === 6) return <CakeIcon />;

            return null;
          }

          console.log(value);
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-fit text-xs text-[#7D848B]">
              {getIcon(value.id)}
              {value.name}
            </Tag>
          );
        },
      },
      {
        key: "count",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("sales-number")}
          </span>
        ),
        dataIndex: "count",
        render(value) {
          return (
            <span className="text-sm whitespace-nowrap text-[#2F3138]">
              {value} {t("piece")}
            </span>
          );
        },
      },
      {
        key: "price",
        title: (
          <span className="font-medium text-[#7D848B]">{t("unit-price")}</span>
        ),
        dataIndex: "price",
        render(value) {
          return (
            <span className="text-sm text-[#2F3138]">
              {formatAmount(value)} UZS
            </span>
          );
        },
      },
      {
        key: "total",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("total-price-sales")}
          </span>
        ),
        dataIndex: "total",
        render(value: number, vals) {
          return (
            <span className="text-sm text-[#2F3138] whitespace-nowrap tabular-nums">
              {formatAmount(vals.count * vals.price ?? 0)} UZS
            </span>
          );
        },
      },
    ];
    return { orders: ordersColumns, products: productsColumns };
  }, [t, activeTab]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    if (isTabletOrMobile) {
      const { width } = document
        .querySelector("table")
        ?.getBoundingClientRect() ?? { width: 0 };

      if (document.querySelector(".ant-table-footer")) {
        document.querySelector(".ant-table-footer").style.width = `${Math.floor(
          width,
        )}px`;
      }
    }
  }, [data, isTabletOrMobile]);

  const logout = (): void => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    hide();

    setIsAuth(false);
  };

  const onShowMore = () => {
    setPageSize((prev) => prev + 10);
  };

  const onClearFilters = (): void => {
    const clearedFilters = {
      self: true,
      shipping: true,
      min_date: null,
      max_date: null,
      date_type: undefined,
      payment_type: undefined,
    };
    setFilters(clearedFilters);
    setFiltersDraft(clearedFilters);
  };

  const tabsItems = useMemo(() => {
    const tabsAllItems: TabsProps["items"] = [
      {
        key: "sold-items",
        label: <span className="text-lg font-medium">{t("sold-items")}</span>,
      },
      {
        key: "orders-list",
        label: <span className="text-lg font-medium">{t("orders-list")}</span>,
      },
    ];

    return tabsAllItems;
  }, [t]);

  return (
    <>
      {isTabletOrMobile ? (
        <Header className="bg-white grid grid-cols-3 items-center fixed bottom-0 z-[1] justify-between border-y-[1px] px-4">
          <Button
            className="border-none flex flex-col gap-1 shadow-none items-center justify-center"
            onClick={() => {
              setOpen(true);
              setIsLoggingOut(false);
            }}
          >
            <PreferencesIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("filter")}</span>
          </Button>
          <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={download}
          >
            <DownloadIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">
              {t("download-excel")}
            </span>
          </Button>
          {/* <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={() => {
              setOpen(true);
              setIsLoggingOut(true);
            }}
          >
            <LogoutIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("logout")}</span>
          </Button> */}
          <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={() => {
              navigate("/expenses");
              // hide();
            }}
          >
            <MoneyIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("expenses")}</span>
          </Button>

          <Drawer
            placement={"bottom"}
            closable={false}
            style={{
              borderRadius: "16px 16px 0 0",
              overflow: "hidden",
            }}
            height="auto"
            classNames={{ body: "!p-0 !pt-4" }}
            onClose={() => setOpen(false)}
            open={open}
          >
            <Button
              className="flex justify-center w-full h-auto"
              type="text"
              onClick={() => setOpen(false)}
            >
              <div className="w-9 h-1 rounded-lg bg-[#A8AAB3]/30"></div>
            </Button>
            <h2 className="text-base text-center font-semibold my-3 text-[#35314D]">
              {isLoggingOut ? t("Подтвердите действие") : t("filter")}
            </h2>
            <Divider className="m-0" />
            <div className="p-6">
              {isLoggingOut ? (
                <div className="flex flex-col items-center justify-center gap-6">
                  <div className="flex flex-col gap-4 items-center">
                    <LogoutIcon style={{ color: "#FF1F00" }} />
                    <span className="w-80 text-center">
                      {t("confirm-logout")}
                    </span>
                  </div>

                  <div className="grid grid-cols-2 gap-4 w-full">
                    <Button
                      size="large"
                      className="bg-[#f5f5f5] border-none"
                      type="text"
                      onClick={() => setOpen(false)}
                    >
                      {t("cancel")}
                    </Button>

                    <Button
                      size="large"
                      className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                      onClick={() => {
                        isLoggingOut && logout();
                      }}
                    >
                      {t("Выйти")}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className=" mb-6">
                    <h1 className="font-medium mb-3 text-base">
                      Выберите промежуток
                    </h1>
                    <Radio.Group
                      value={filters.date_type}
                      onChange={(e: any) => {
                        setFilters((prevFilters) => {
                          return {
                            ...prevFilters,
                            date_type: e.target.value,
                            min_date: null,
                            max_date: null,
                          };
                        });
                      }}
                    >
                      <Space direction="vertical">
                        {dateTypes.map((val) => (
                          <Radio key={val.label + val.value} value={val.value}>
                            {val.label}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                  <h1 className="font-medium mb-3 text-base">
                    Выберите период
                  </h1>
                  <div className="grid grid-cols-2 gap-4">
                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={t("from") ?? ""}
                      value={
                        filters.min_date
                          ? dayjs(filters.min_date, "DD.MM.YYYY")
                          : null
                      }
                      onChange={(_, stringDate) =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          min_date: stringDate,
                          date_type: undefined,
                        }))
                      }
                      inputReadOnly
                    />

                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={t("to") ?? ""}
                      value={
                        filters.max_date
                          ? dayjs(filters.max_date, "DD.MM.YYYY")
                          : null
                      }
                      onChange={(_, stringDate) =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          max_date: stringDate,
                          date_type: undefined,
                        }))
                      }
                      inputReadOnly
                    />
                    <Button
                      type="primary"
                      onClick={onClearFilters}
                      className="flex items-center text-[#2F3138] bg-[#F5F5F5] justify-center"
                      loading={isLoading}
                    >
                      {t("Очистить")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={onApplyChanges}
                      className="flex items-center justify-center"
                      loading={isLoading}
                    >
                      {t("apply")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Drawer>
        </Header>
      ) : (
        <Header className="bg-white flex items-center justify-between border-y-[1px]">
          <h1 className="text-2xl font-semibold">{t("reports")}</h1>

          <div className="flex items-center gap-2">
            <Select
              className="w-40"
              placeholder={t("Выберите дату")}
              options={dateTypes}
              value={filters.date_type}
              onChange={(id: string) => {
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    date_type: id,
                    min_date: null,
                    max_date: null,
                  };
                });
                setFiltersDraft((prevFilters) => {
                  return {
                    ...prevFilters,
                    date_type: id,
                    min_date: null,
                    max_date: null,
                  };
                });
              }}
            />

            <DatePicker
              // format="DD.MM.YYYY"
              allowClear={false}
              placeholder={t("from") ?? ""}
              value={filters.min_date ? dayjs(filters.min_date) : null}
              onChange={(_, stringDate) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  min_date: stringDate,
                  date_type: undefined,
                }))
              }
              inputReadOnly
            />

            <DatePicker
              // format="DD.MM.YYYY"
              allowClear={false}
              placeholder={t("to") ?? ""}
              value={filters.max_date ? dayjs(filters.max_date) : null}
              onChange={(_, stringDate) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  max_date: stringDate,
                  date_type: undefined,
                }))
              }
              inputReadOnly
            />

            <Button
              type="primary"
              onClick={onApplyChanges}
              className="flex items-center justify-center"
              loading={isLoading}
              disabled={!filters.min_date || !filters.max_date}
            >
              {t("apply")}
            </Button>

            <Button
              type="primary"
              onClick={download}
              className="flex items-center justify-center"
            >
              <DownloadIcon />
              {t("download-excel")}
            </Button>
          </div>
        </Header>
      )}

      <Content className="bg-[#f5f5f5] px-4 py-6">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                height: isTabletOrMobile
                  ? "calc(100vh - 64px - 64px - 24px - 24px - 80px - 56px - 77px - 16px - 16px - 1px)"
                  : "calc(100vh - 64px - 64px - 60px - 24px - 24px - 55px - 16px - 16px - 1px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <NoteIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-reports")}
                </span>
              </div>
            </div>
          )}
        >
          <div className="mb-6 flex flex-col gap-4">
            <div className="grid grid-cols-2 md:grid-cols-5 gap-3 md:gap-4">
              <div className="col-span-2 md:col-span-1 h-full">
                <TotalStatsCard
                  filter={filtersDraft.date_type}
                  title={t("total-income")}
                  data={data?.raiseStats.total}
                />
              </div>
              <TotalStatsCard
                filter={filtersDraft.date_type}
                title={t("total-expenses")}
                data={data?.expenseStats}
              />
              <TotalStatsCard
                filter={filtersDraft.date_type}
                title={t("total-sales")}
                data={data?.raiseStats.count}
              />
              <TotalStatsCard
                filter={filtersDraft.date_type}
                title={t("total-takeaway")}
                data={data?.raiseStats.type_self_percent}
              />
              <TotalStatsCard
                filter={filtersDraft.date_type}
                title={t("total-delivery")}
                data={data?.raiseStats.type_shipping}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="md:col-span-2">
                <SalesChart
                  data={data?.typeStats}
                  filter={filtersDraft?.date_type}
                />
              </div>
              <PaymentChart
                data={data?.paymentStats}
                title={t("popular-payment-stats")}
              />
              <PaymentChart
                data={data?.categoryStats.data}
                title={t("popular-category-stats")}
              />
            </div>
          </div>

          {isTabletOrMobile ? (
            <h1 className="text-lg font-semibold mb-6">
              {t("orders-list")} ({ordersData?.count})
            </h1>
          ) : (
            <Tabs
              items={tabsItems}
              defaultActiveKey={activeTab}
              // activeKey={activeTab}
              onChange={(val) => setActiveTab(val)}
            />
          )}
          {isTabletOrMobile ? (
            <div className="flex flex-col gap-3 pb-28">
              {orders?.length ? (
                orders?.map((val) => (
                  <MobileOrderCard
                    key={"mobile-order-card-" + val.id}
                    {...val}
                  />
                ))
              ) : ordersData?.count === 0 ? (
                <div className="flex flex-col items-center gap-2">
                  <NoteIcon style={{ color: colors.primary }} />
                  <span className="text-base leading-6 font-normal text-[#2F3138]">
                    {t("no-reports")}
                  </span>
                </div>
              ) : (
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
                  <MobileOrderCardSkeleton key={"mobile-order-card-" + val} />
                ))
              )}
              {orders?.length === ordersData?.count ? null : (
                <Button
                  type="primary"
                  size="large"
                  className="bg-[#5566FF]/10 text-[#5566FF] font-medium text-sm"
                  disabled={!orders}
                  onClick={onShowMore}
                >
                  {t("show-ten-orders")}
                </Button>
              )}
            </div>
          ) : (
            <>
              <Table
                id="orders-list"
                rowKey={(record) => record.id}
                showHeader={true}
                columns={columns.orders}
                dataSource={ordersData?.results}
                onChange={onPageChange}
                pagination={ordersPaginationProps}
                loading={isOrdersLoading || isOrdersFetching}
                className={clsx(
                  "overflow-x-auto bg-white",
                  isTabletOrMobile
                    ? "custom-report-table-mobile"
                    : "custom-report-table",
                  activeTab === "orders-list" ? "" : "hidden",
                )}
              />
              <Table
                id="sold-items"
                rowKey={(record) => record.id}
                showHeader={true}
                columns={columns.products}
                dataSource={productsData?.results}
                onChange={onProductsPageChange}
                pagination={productsPaginationProps}
                loading={isProductsLoading || isProductsFetching}
                className={clsx(
                  "overflow-x-auto bg-white",
                  isTabletOrMobile
                    ? "custom-report-table-mobile"
                    : "custom-report-table",
                  activeTab === "sold-items" ? "" : "hidden",
                )}
              />
            </>
          )}
        </ConfigProvider>
      </Content>
    </>
  );
}

function getOrderType(filters: {
  self: boolean;
  shipping: boolean;
}): OrderType | undefined {
  const { self, shipping } = filters;

  if (self && shipping) return undefined;

  if (self) return "self";

  if (shipping) return "shipping";

  return undefined;
}
